import PropTypes from "prop-types";
import Head from "next/head";

const MetaDatos = (props) => {

  let currency = props.currency || 'mxn';
  const transformsUSD = ['us'];

  if(transformsUSD.includes(currency.toLocaleLowerCase())) {
    currency = 'USD';
  }

    // Valores predeterminados
    const defaultTitle = "Inmobiliaria y financiera | Tu Casa Mi Casa";
    const defaultDescription = "Encuentra tu hogar ideal en nuestro portal. Amplia selección de propiedades y herramientas para simplificar tu búsqueda. ¡Descubre hoy tu nuevo hogar!";
    const defaultUrl = "https://tucasamicasa.com";
    const defaultImage = "https://tucasamicasa.com/img/tucasamicasa.png";
    const defaultKeywords = "Tu Casa Mi Casa";

  const canonicalURL = props.url ? props.url.replace(/-pagina-\d+/, '') : defaultUrl;

  //schema Agent
  const actionType = props.action === "venta" ? "BuyAction" : "RentAction";
  const actionSchema = {
    "@context": "http://schema.org",
    "@type": actionType,
    agent: {
      "@type": "RealEstateAgent",
      name: "Tu casa mi casa",
      image: "https://tucasamicasa.com/img/tucasamicasa.png",
      telephone: "+52 55 4169 5958",
      address: {
        "@type": "PostalAddress",
        streetAddress: "Moras 449, Tlacoquemecatl del Valle",
        addressLocality: "Ciudad de México",
        postalCode: "03200",
        addressCountry: "MX",
      },
      geo: {
        "@type": "GeoCoordinates",
        latitude: "19.3730029",
        longitude: "-99.1741803",
      },
      sameAs: [
        "https://www.facebook.com/tucasamicasaof",
        "https://www.linkedin.com/company/tu-casa-mi-casa/",
        "https://www.instagram.com/tucasamicasaof/",
      ],
    },
  };

  //schema propiedades
  const isInmueble = props.inmueble;
  const schemaProperties = {
    "@context": "https://schema.org",
    "@type": "Product",
    name: props.name,
    image: [
      "https://tucasamicasa.com/img/tucasamicasa.png"
    ],
    description: props.descripProperty,
    brand: {
      "@type": "Organization",
      name: "Tu Casa Mi Casa"
    },
    offers: {
      "@type": "Offer",
      url: props.url,
      price: props.price,
      priceCurrency: currency,
      availability: "https://schema.org/InStock"
    },
  };

  const RealEstateProperties = {
    "@context": "https://schema.org",
    "@type": "RealEstateListing",
    name: props.name,
    image: [
      "https://tucasamicasa.com/img/tucasamicasa.png"
    ],
    description: props.descripProperty,
    offers: {
      "@type": "Offer",
      url: props.url,
      price: props.price,
      priceCurrency: currency,
      availability: "https://schema.org/InStock"
    },
  };

  const OrganizationProperties = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    'name': 'Tu Casa Mi Casa',
    'url': 'https://tucasamicasa.com/home',
    'logo': 'https://tucasamicasa.com/img/tucasamicasa.png',
  };

  const WebsiteProperties = {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    'name': 'Tu Casa Mi Casa',
    'url': 'https://tucasamicasa.com/home',
  };

  return (
    <Head>
      <title>{props.title || defaultTitle}</title>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(OrganizationProperties) }}
      />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(WebsiteProperties) }}
      />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(actionSchema) }}
      />
      {
        isInmueble ?
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaProperties) }}
        /> : null
      }
      {
        isInmueble ?
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{ __html: JSON.stringify(RealEstateProperties) }}
          /> : null
      }
      <meta name="description" content={props.description || defaultDescription} />
      <meta name="keywords" content={props.keywords || defaultKeywords} />
      <meta name="robots" content="index, follow" />
      <meta property="og:type" content="website" />
      <meta name="og:title" property="og:title" content={props.title || defaultTitle} />
      <meta
        name="og:description"
        property="og:description"
        content={props.description || defaultDescription}
      />
      <meta property="og:site_name" content="Tu Casa Mi Casa" />
      <meta property="og:url" content={props.ur || defaultUrl} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={props.title || defaultTitle} />
      <meta name="twitter:description" content={props.description || defaultDescription} />
      <meta name="twitter:site" content="@tucasamicasa" />
      <link rel="apple-touch-icon" sizes="180x180" href="/siteIcon/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/siteIcon/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/siteIcon/favicon-16x16.png" />
      <link rel="manifest" href="/siteIcon/site.webmanifest" />
      <link rel="mask-icon" href="/siteIcon/safari-pinned-tab.svg" color="#5bbad5" />
      <meta name="msapplication-TileColor" content="#da532c" />
      <meta name="theme-color" content="#ffffff" />
      <meta property="og:image" content={props.image || defaultImage} />
      <link rel="preconnect" href="https://s3.us-east-1.amazonaws.com" />
      <link rel="dns-prefetch" href="https://s3.us-east-1.amazonaws.com" />
      <link rel='canonical' href={canonicalURL} />
      <meta name="google-site-verification" content="uc1Qkmj9y1IlFUfR407jwsd9z3OcF4f6XF8wXzwkdEs" />
      <meta
        name="twitter:image"
        content="https://tucasamicasa.com/img/tucasamicasa.png"
      />
    </Head>
  );
};

MetaDatos.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  url: PropTypes.string,
  image: PropTypes.string,
  keywords: PropTypes.string,
};

export default MetaDatos;
