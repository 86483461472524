import coloredLogo from "public/img/TCMC_Portal_inmobiliario.png";
import whiteLogo from 'public/img/TCMC Portal inmobiliario__web-white.png';
import Image from "next/image";

export default function Logo (isHome) {
   return (
     <div  className="w-56 sm:w-96 m-4">
       <a href="/">
         <Image
           priority={true}
           src={isHome.isHome ? whiteLogo : coloredLogo}
           width={340}
           height={56}
           alt="tucasamicasa"
           placeholder="blur"
           style={{
             maxWidth: "100%",
             height: "auto"
           }} />
       </a>
     </div>
   );
}
